// TODO: refactor to TS
import { defineStore } from 'pinia'
import toast from '@/libs/toast'

import { usePersonalStore } from '@/modules/account'
import {
  createOrganization,
  getMemberships,
  getOrganizationById,
  getOwnOrganizationsList,
  organizationLeave,
  updateOrganizationById,
  updateOrganizationAssets,
  updateOrganizationPublicSettings,
  getOrganizationPublicData,
} from '@/api/organization'
import metricsApi from '@/modules/metrics/api/metrics'
import { i18nUtils } from '@keyo/core/i18n'

export const MEMBERSHIP_STATUS = {
  INVITATION_SENT: 0,
  ACTIVE: 1,
  PAUSED: 2,
  DEACTIVATED: 3,
}

const allowedRoles = ['Owner', 'Admin', 'User']

const prepareOrg = ({ organization_roles: roles, membership, ...org }) => {
  // Filter by allowed roles
  if (roles) {
    org.roles = roles.filter(r => allowedRoles.includes(r.name))
  }

  // public profile do not have `name` or `dba`
  if (org.name) {
    org.displayName = org.dba || org.name // `name` if legal name, `dba` can be provided as alternative
    org.initials = org.displayName[0]
  }

  if (org.payment_provider_ids) {
    // currently available only 1 integration via payment provider and enabled internally per organization
    org.hasIntegrations = !!org.payment_provider_ids.length
  }

  // membership not always included in response
  if (membership) {
    org.member = {
      role: membership.organization_role.name,
      status: membership.status,
      id: membership.id,
    }
  }

  // set correct initial values for inputs
  if (org.billing_address?.country === null) {
    org.billing_address.country = {
      code: '',
      name: '',
    }
  }
  return org
}

function setItem(payload) {
  const id = String(payload.id)
  const assignTo = this.items.get(id)
  const prepared = prepareOrg(payload)
  assignTo ? Object.assign(assignTo, prepared) : this.items.set(id, prepared)
}

export const useOrganizationsStore = defineStore('organizations', {
  state: () => ({
    items: new Map(),
    order: [], // maybe sidebar order
    scansMetricsByOrg: new Map(),
    averageMetricsByOrg: new Map(),
    activeMembersCountLastMonth: new Map(),
    activeMembersCount: new Map(),
  }),
  actions: {
    setItem,
    async create(body) {
      await createOrganization(body)
      await this.getList() // TODO: fix/workaround: membership missing in response

      // TODO: fix/workaround: personal organization widget currently checked via personal profile.
      const personal = usePersonalStore()
      await personal.profileFetch()
    },

    async getList() {
      const resp = await getOwnOrganizationsList()
      this.$patch(s => {
        resp.data.results.forEach(o => setItem.call(s, o))
      })
    },

    async fetchById(id, options = { redirect: true }) {
      try {
        // TODO: required to merge default and public profile. missing from API: `email`
        const [all, pub] = await Promise.all([
          getOrganizationById(id),
          getOrganizationPublicData(id),
        ])
        this.setItem(Object.assign(all.data, pub.data))
      } catch (e) {
        toast.show("Organization not found or you don't have permission", 'error')
        options?.redirect && this.router.push('/personal')
        console.error(e)
        throw e
      }
    },

    async update(id, body) {
      const resp = await updateOrganizationById(id, body)
      this.setItem(resp.data)
    },

    async updatePublicSettings(id, body) {
      const resp = await updateOrganizationPublicSettings(id, body)
      this.setItem(resp.data)
    },

    /**
     *
     * @param {*} id Organization id
     * @param {Object} assets Assets to update: supports logo and background
     * @param {File | ''} [assets.logo]
     * @param {File | ''} [assets.background]
     */
    async updateAssets(id, assets) {
      const resp = await updateOrganizationAssets(id, assets)
      this.setItem(resp.data)
    },

    async leave(orgId) {
      try {
        await organizationLeave(orgId)
        this.items.delete(`${orgId}`)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchAverageMetricsByOrg(orgId) {
      try {
        const { data } = await metricsApi.organization.getAverageMetrics(orgId)
        this.averageMetricsByOrg.set(orgId, data)
      } catch (e) {
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },

    async fetchScanMetricsByOrg(orgId) {
      try {
        const { data } = await metricsApi.organization.getScanMetrics(orgId)
        this.scansMetricsByOrg.set(orgId, data)
      } catch (e) {
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },

    async fetchActiveMembersCount(orgId) {
      try {
        const { data } = await getMemberships(orgId, {
          offset: 0,
          limit: 1, // We only need the count
          status: MEMBERSHIP_STATUS.ACTIVE,
        })
        this.activeMembersCount.set(orgId, data.count)
      } catch (e) {
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },

    async fetchActiveMembersCountLastMonth(orgId) {
      try {
        const now = new Date()
        const formatUTCDate = date => date.toISOString().split('T')[0] // YYYY-MM-DD
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1) // First day of the current month
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1) // First day of the next month

        const { data } = await getMemberships(orgId, {
          offset: 0,
          limit: 1, // We only need the count
          date_joined__gte: formatUTCDate(startOfMonth),
          date_joined__lt: formatUTCDate(endOfMonth),
          status: MEMBERSHIP_STATUS.ACTIVE,
        })
        this.activeMembersCountLastMonth.set(orgId, data.count)
      } catch (e) {
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },
  },
  getters: {
    organizations() {
      return Array.from(this.items.values())
    },
    /** organizations where user has some administrative access */
    spaces() {
      return Array.from(this.items.values()).filter(
        o => o.member.role !== 'User' && o.member.status.value === MEMBERSHIP_STATUS.ACTIVE,
      )
    },
    // atm for network widget
    networks() {
      return Array.from(this.items.values()).filter(
        o => o.member.role === 'User' && o.member.status.value === MEMBERSHIP_STATUS.ACTIVE,
      )
    },
    withPayments() {
      return Array.from(this.items.values()).filter(o => !!o.payment_provider_ids.length)
    },
  },
})
