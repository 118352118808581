export default {
  'Personal account': 'Cuenta personal',
  'welcomeTo': '¡Bienvenido a {name}!',
  'youHaveBeenInvitedBy': 'Has sido invitado por {name}.',
  'pleaseCompleteFormToAction':
    'Por favor, completa el formulario a continuación para {action} tu cuenta.',
  'logInTo': 'iniciar sesión en',

  'welcomeToOrgJoin': '@:welcomeTo @:pleaseCompleteFormToAction',
  'welcomeToOrgJoinLogin': '@:welcomeTo',
  'welcomeToOrgInvitation': '¡Bienvenido! @:youHaveBeenInvitedBy @:pleaseCompleteFormToAction',

  'Invitation URL': 'URL de invitación',

  'Integrations': 'Integraciones',
  'Address book': 'Libreta de direcciones',
  'Addresses that are associated with your account': 'Direcciones asociadas a tu cuenta.',
  'Make changes to your address': 'Realiza cambios en tu dirección.',
  'Provide your address details': 'Proporciona los detalles de tu dirección.',

  'Your address has been removed': 'Tu dirección ha sido eliminada.',
  'Remove your address': 'Eliminar tu dirección',
  'Are you sure you want to remove this address from your profile?':
    '¿Estás seguro de que quieres eliminar esta dirección de tu perfil?',
  'Remove address': 'Eliminar dirección',
  'Add your address': 'Añadir tu dirección',
  'Enter your address in the fields provided below':
    'Introduce tu dirección en los campos a continuación.',
  'Select your preferred address from the options below':
    'Selecciona tu dirección preferida de las opciones a continuación.',
  'organizationHasARequirement':
    '{name} tiene un requisito, por favor haz clic en continuar para completarlo.',
  'Personal details': 'Detalles personales',
  'Add new address': 'Añadir nueva dirección',
  'Organization settings': 'Configuración de la organización',
  'byJoiningOrgYouAgreeToPartnersLegal': 'Al unirte a {name} estás aceptando {legal}.',
  'keyoDisclaimer':
    'Keyo, Inc. es un procesador de tercer nivel que facilita tu transacción con {name}. Lee nuestra {privacy} aquí.',
  'Delete': 'Eliminar',
  'Organization information updated': 'Información de la organización actualizada',
  'Failed to update organization information':
    'Error al actualizar la información de la organización',

  'Default card': 'Tarjeta principal',
  'Delete card': 'Eliminar tarjeta',
  'onceYouTapDeleteCardWillBeDeletedPermanently':
    'Una vez que toques {action}, tu tarjeta se eliminará permanentemente de tu cuenta.',
  'Your card has been deleted successfully': 'Tu tarjeta ha sido eliminada con éxito.',
  'membersJoinedOverThePastMonth':
    'En el último mes, {count} miembro se unió a la organización. | En el último mes, {count} miembros se unieron a la organización.',
  'Average identification time': 'Tiempo promedio de identificación',
  'Successful identifications': 'Identificaciones exitosas',
  'Scans': 'Escaneos',
  'vs yesterday': 'comparado con ayer',
  'Successful scans per application': 'Escaneos exitosos por aplicación',
  'Total scans': 'Escaneos totales',
  'Identification app': 'App de identificación',
  'Access app': 'App de acceso',
  'Number of scans': 'Número de escaneos',
  'Members in organization': 'Miembros en la organización',
  'Daily': 'Diario',
  'Monthly': 'Mensual',
  'Yearly': 'Anual',
  'You don"t have any cards in your wallet': 'No tienes ninguna tarjeta en tu billetera.',
  'Seconds': 'Segundos',
  'Feature flags': 'Banderas de características',
  'Enable beta languages': 'Habilitar idiomas beta',
  'Enable new dashboard': 'Habilitar nuevo dashboard',
  'Missing a feature?': '¿Falta una funcionalidad?',
  'We value your suggestions!': '¡Valoramos tus sugerencias!',
  'Let us know, and we’ll add it to our roadmap!':
    '¡Haznos saber y lo agregaremos a nuestra hoja de ruta!',
  'We’d love your help in taking Keyo to the next level':
    'Nos encantaría tu ayuda para llevar Keyo al siguiente nivel.',
  'Invite a new member to the organization!': '¡Invita a un nuevo miembro a la organización!',
  'You can do it with dashboard!': '¡Puedes hacerlo con el dashboard!',
  'Learn more about new Integrations': 'Aprende más sobre las nuevas integraciones.',
  'New feature!': '¡Nueva funcionalidad!',
  'Learn how to manage your organization’s profile':
    'Aprende cómo gestionar el perfil de tu organización.',
  'Want to help members enroll their palm? Learn more!':
    '¿Quieres ayudar a los miembros a inscribir su palma? ¡Aprende más!',
  'Your organizations': 'Tus organizaciones',
  'vs': 'vs',
  'NV': 'SV',
  'No value': 'Sin valor',
  'No organizations': 'No hay organizaciones',
  'You haven"t joined any organizations yet': 'Aún no te has unido a ninguna organización.',
  'Metrics': 'Métricas',
  'Show more': 'Mostrar más',
  'Show less': 'Mostrar menos',
  'Guides & Tools': 'Guías y herramientas',
  'Account overview': 'Resumen de la cuenta',
  'emulationActionUserSuccess': 'Emulación de "{action}" para {user} realizada con éxito.',
  'Emulation unexpected error': 'Error inesperado en la emulación.',
  'Action': 'Acción',
  'Perform': 'Realizar',
  'Enroll': 'Inscribir',
  'Identify': 'Identificar',
  'System activity emulator': 'Emulador de actividad del sistema',
  'Emulate failed palm identification': 'Emular identificación fallida de palma',
  'Identification failed': 'Identificación fallida',
  'Emulated Failed Identification successfully':
    'Emulación de "Identificación Fallida" realizada con éxito.',
  'ofTotalPages': 'de {totalPages}',
  'Invitations': 'Invitaciones',
  'youHavePendingInvitationsPleaseCheckThem':
    'Tienes {amount} invitación pendiente—¡revísala ahora! | Tienes {amount} invitaciones pendientes—¡revísalas!',
  'Remove': 'Eliminar',
  'Transfer ownership': 'Transferir propiedad',

  'common': {
    helpUsEnhance: '¡Por favor, ayúdenos a mejorar nuestros servicios!',
    createNewOrganization: 'Crear nueva organización',
    orgLegalMessage: 'Al unirte a esta organización, aceptas los {legal} de este socio.',
  },
  'layouts': {
    keyoLogoWide: 'Logotipo de Keyo ancho',
    keyoLogoSquare: 'Logotipo de Keyo cuadrado',
    biometrics: 'Biométricos',
    deviceManagement: 'Gestión de Dispositivos',
    developers: 'Desarrolladores',
    webhookManagement: 'Gestión de Webhooks',
    systemActivityEmulator: 'Simulador de Actividad',
    apiReference: 'Referencia de API',
    postmanCollection: 'Colección de Postman',
    developersHub: 'Centro Desarrolladores',
    settings: 'Configuración',
    helpCenter: 'Centro de Ayuda',
    knowledgeHub: 'Centro de Conocimiento',
    logOut: 'Cerrar Sesión',
    toggleSidebar: 'Alternar Barra Lateral',
    administrator: 'Administrador',
    personal: 'Personal',
    profileSettings: 'Preferencias del perfil',
    wallet: 'Cartera',
    dashboard: 'Tablero',
    preferences: 'Preferencias',
  },
  'components': {
    btnResend: {
      resendSecurityCode: 'Reenviar código de seguridad',
      resendSecurityCodeIn: 'Reenviar código de seguridad en {remaining}s',
    },

    actionsDropdown: {
      showMenu: 'Mostrar menú',
      hideMenu: 'Ocultar menú',
    },
    modals: {
      'videoPlayer': {
        notSupported: 'Tu navegador no soporta el video.',
      },
      'changeRole': {
        roleChanged: 'El rol ha sido cambiado con éxito',
      },
      'createOrganization': {
        title: 'Creación de Organización',
        subtitle: 'Por favor, completa el formulario a continuación.',
        created: 'La organización ha sido creada',
        failedCreated: 'No se pudo crear la organización',
      },
      'membersPause': {
        title: 'Pausar Usuario',
        areYouSure: '¿Estás seguro de que quieres pausar',
        confirmText:
          'Haz clic en ‘confirmar’ para continuar. Los usuarios recibirán una notificación por correo electrónico sobre la pausa de su cuenta.',
        pauseSuccess: 'El miembro {name} ha sido pausado.',
        pauseError: 'Error inesperado al intentar pausar a {name}. Inténtalo de nuevo más tarde',
      },
      'membersRenew': {
        title: 'Renovar Usuario',
        areYouSure: '¿Estás seguro de que quieres renovar',
        confirmText:
          'Haz clic en ‘confirmar’ para continuar. Los usuarios recibirán una notificación por correo electrónico de su membresía renovada.',
        renewSuccess: 'El miembro {name} ha sido renovado.',
        renewError: 'Error inesperado al intentar renovar a {name}. Inténtalo de nuevo más tarde',
      },
      'Leave this window?': '¿Salir de esta ventana?',
      'leaveWithoutSaving?':
        '¿Está seguro de que desea salir de esta ventana? Los cambios que realice no se pueden guardar.',
    },
  },
  'modules': {
    apiCreds: {
      noApiCredentials: 'No hay credenciales de API',
      keyDescription:
        'La clave te permitirá autenticar las solicitudes de API.\nGuarda siempre tu clave secreta en un lugar seguro y protegido.',
      toGetStarted: 'Para comenzar, haz clic en "Crear clave secreta" para crear una.',
      createSecretKey: 'Crear clave secreta',
      secretKey: 'Clave secreta',
      rotate: 'Rotar',
      credentialsName: 'Nombre de las credenciales',
      modals: {
        secretRotateConfirm: {
          title: '¿Rotar la clave secreta?',
          description: '¿Estás seguro de que quieres rotar la clave secreta?',
        },
        secretCreated: {
          description:
            'Asegúrate de guardarla - no podrás acceder a ella de nuevo. Si pierdes esta clave secreta, tendrás que generar una nueva.',
        },
      },
    },
    auth: {
      common: {
        expiredLink:
          'El enlace ha caducado o no es válido. Por favor revise su correo electrónico para obtener más instrucciones o solicite uno nuevo.',
        didNotGetCode: '¿No recibiste un código de seguridad?',
        pleaseAddMethod: 'Por favor, añade tu {method}',
        changeVerificationMethod: 'Cambiar método de verificación',
        verifyMethod: 'Verificar {method}',
        pleaseVerifyMethod: 'Para ayudar a proteger tu identidad, verifica tu {method}.',
        resendEmail: 'Reenviar correo',
        unexpectedLoginSessionError: 'Error inesperado de sesión de inicio.',
        completeFormToCreateAccount:
          'Por favor, completa el formulario a continuación para crear tu cuenta.',
        checkEmail: 'Revisa tu correo electrónico',
        activationAccountExpiredLink:
          'El enlace de activación de tu cuenta ya no es válido. Por favor, proporciona el correo electrónico asociado con tu cuenta de Keyo para solicitar un nuevo enlace de activación.',
        activationLinkExpired: 'El enlace de activación ha caducado',
        emailOrPhoneNumberExample: "Ejemplo nombre{'@'}ejemplo.com o +1123456789.",
        forgotPassword: '¿Olvidaste tu contraseña?',
      },
      components: {
        policyInput: {
          readAndAccept: 'He leído y acepto',
        },
      },
      modals: {
        activationLinkSuccess: {
          heading: 'Revisa tu {method}',
          methods: {
            phone: 'teléfono',
            email: 'correo electrónico',
          },
          description: 'Por favor, activa tu cuenta usando el mensaje que enviamos a tu {method}.',
        },
      },
      pages: {
        forgotPasswordInstruction: {
          heading: 'Olvidé mi contraseña',
          description: {
            enterEmail:
              'Para restablecer tu contraseña, por favor introduce tu correo electrónico o número de teléfono a continuación.',
            checkMethod:
              'Un enlace para restablecer la contraseña ha sido enviado a {method}. Abre el enlace para continuar.',
          },
          checkMessages: 'Revisa tus mensajes',
          didNotReceiveMessage: '¿No recibiste el mensaje?',
          didNotReceiveEmail: '¿No recibiste el correo electrónico?',
          resendMessage: 'Reenviar mensaje',
        },
        activationEmail: {
          cannotResendEmail:
            'No podemos reenviar el email. Por favor, inténtalo de nuevo más tarde.',
          confirmationEmailResent: '¡Se ha reenviado un email de confirmación!',
          emailSentTo: 'Enviamos un email de confirmación a',
          checkEmail: 'Revisa tu email y haz clic en el enlace de activación para continuar.',
        },
        activationPassword: {
          heading: 'Crea tu contraseña',
          description: 'Por favor, completa el siguiente formulario para activar tu cuenta.',
          readOur: 'Lee nuestro',
          readAndAccept: 'He leído y acepto',
        },
        forgotPasswordSet: {
          createNewPassword: 'Crea una nueva contraseña',
          enhanceAccountSecurity: 'Mejora la seguridad de tu cuenta con una contraseña fuerte',
          youCanNowUseYourNewPassword:
            'Ahora puedes usar tu nueva contraseña para iniciar sesión en tu cuenta.',
        },
        fullName: {
          reviewHeading: 'Revisa tu nombre completo',
          reviewDescription:
            'Por favor, asegúrate de que la información a continuación es correcta.',
          enterHeading: 'Ingresa tu nombre completo',
          enterDescription:
            'Por favor, completa el siguiente formulario para terminar de activar tu cuenta.',
        },
        kioskActivationResend: {
          resendActivationLink: 'Reenviar enlace de activación',
        },
        kioskActivationResendSuccess: {
          description: 'Se ha enviado un nuevo enlace de invitación a {email}.',
        },
        signIn: {
          description: 'Por favor complete el formulario a continuación para iniciar sesión.',
          descriptionInactive: 'Por favor, introduce tu correo electrónico o número de teléfono.',
          wrongCredentials: {
            text: 'Por favor, verifica tu correo electrónico o teléfono y contraseña, asegurándote de que coincidan con las credenciales utilizadas al registrarte. Si el problema persiste, por favor',
            contactSupport: 'contacta con soporte.',
          },
        },
        register: {
          hint: 'Al continuar, aceptas los {terms} y {privacy} de Keyo.',
          disclaimer: 'Descargo de responsabilidad',
          disclaimerMessage:
            'Keyo, Inc. es un procesador de tercer nivel que facilita tu transacción con {name}.',
        },
        activationVerifyInstruction: {
          verifyItIsYou: 'Verifica que eres tú',
          pleaseSelectPreferredMethodForVerifying:
            'Por favor selecciona tu método preferido para verificar tu cuenta. Usando el método seleccionado, podrás iniciar sesión en tu cuenta.',
          ifYouHaveNotReceivedCode:
            'Si no has recibido el código, actualiza tu número de teléfono o elige un método de verificación alternativo.',
        },
        activationVerifySet: {
          verifyPhoneNumber: 'Verificar número de teléfono',
          verifyEmail: 'Verificar correo electrónico',
          description:
            'Se enviará un código de seguridad a {recipient}.\n Por favor, introduce el código de 6 dígitos a continuación.',
        },
        activationSecureAccount: {
          heading: 'Asegura tu cuenta',
          description:
            'Por favor, crea una contraseña para iniciar sesión y mantener segura tu cuenta.',
        },
        editMethod: {
          headingEmail: 'Editar correo electrónico',
          headingPhone: 'Editar número de teléfono',
          descriptionEmail: 'Por favor, actualiza tu correo electronico a continuación.',
          descriptionPhone: 'Por favor, actualiza tu teléfono a continuación.',
        },
        secureCode: {
          verifyIdentity: 'Bienvenido de nuevo a Keyo',
          securityCodeHasBeenSent:
            'Se enviará un código de seguridad a {recipient}.\\A Por favor, introduce el código de 6 dígitos a continuación',
        },
        signup: {
          description:
            'Para activar tu Tablero Keyo por favor completa el formulario a continuación',
          readAndAccept: 'He leído y acepto',
        },
        forgotPasswordExpired: {
          heading: 'Enlace caducado',
          description:
            'El enlace de restablecimiento de contraseña proporcionado ha caducado. Puedes solicitar otro a continuación.',
          tryAgain: 'Intentar de nuevo',
        },
      },
    },
    // demo ui
    admin: {
      deviceLocationGallery: {
        addMorePhotos: 'Añadir más fotos',
        showGallery: 'Mostrar galería',
      },
      noDevices: {
        addDevices: 'añadir dispositivos',
        displaySoon: 'Se mostrarán aquí pronto',
        workingOnIt: '¡El equipo de Keyo está trabajando en ello!',
      },
      deviceInfo: {
        saveSuccess: 'Información del dispositivo guardada',
        unexpectedError: 'Error inesperado durante la actualización del perfil del dispositivo',
      },
      inviteUsers: {
        message: '¡Invita a tus primeros usuarios!',
      },
      usersListSearch: {
        pauseMembers: 'Pausar miembros',
        renewMembers: 'Renovar miembros',
        removeMembers: 'Remover miembros',
      },
      inviteMembersToYourOrganization: 'Invitar miembros a tu organización',
      enterEmail: 'Introducir correo electrónico',
      invitationSent: 'La invitación ha sido enviada a {email}',
      // widget labels
      invite: 'Invitar',
      letsBuild: '¡Construyamos algo increíble!',
      scanningOnWave: 'Escaneando palma en un dispositivo de identificación biométrica Keyo Wave+',
      feedback: '¿Tienes comentarios?\n¡Nos encantaría saber más!',
      inviteMemberTitle: '¿Quieres invitar a un nuevo miembro?',
      inviteMemberDescription: '¡Puedes hacerlo desde el Panel de Control!',
      userCheckMarkIcon: 'Usuario con una marca de verificación encima',
      confirmOwnershipTransfer: 'Confirmar transferencia de propiedad',
      confirmOwnershipTransferDesc:
        'Se enviará un código de seguridad a {method}. Introduce el código de seguridad y pulsa Confirmar para transferir la propiedad de la organización a {nextOwner}.',
      securityCode: 'Código de Seguridad',
      enterSecurityCode:
        'Por favor, introduce el código de 6 dígitos enviado a {email} para confirmar la transferencia de propiedad.',
      resendSecurityCode: 'Reenviar código de seguridad',
      learnMoreAboutKeyo: 'Aprende más sobre el Panel de Administración de Keyo',
      membersInYourOrganization: 'miembros en tu organización',
      organizationDashboard: 'Panel de {name}',
      dashboardDescription:
        '¡Bienvenido al Panel de tu organización! Desde aquí puedes acceder a herramientas para desarrolladores, comprar dispositivos, invitar a administradores, desarrolladores y miembros, ¡y mucho más!',
    },
    webhooks: {
      title: 'Webhooks',
      description:
        'Los webhooks son una forma en que un sistema puede notificar a otro sistema completamente separado cuando ocurren ciertos eventos. Más específicamente, un webhook es un punto final de API que crea en su servidor, con código para procesar los datos del evento provenientes del backend de Keyo.',
      createEndpoint: 'Crear punto final',
      hostedEndpoints: 'Puntos finales alojados',
      registeredEndpoints: 'Puntos finales registrados para escuchar y manejar eventos.',
      url: 'URL',
      secret: 'Secreto',
      created: 'Creado',
      set: 'Establecido',
      notSet: 'No establecido',
      active: 'Activo',
      disabled: 'Deshabilitado',

      plusIcon: 'Webhook con icono de más',
      penIcon: 'Webhook con icono de lápiz',

      webhookCreation: 'Creación de Webhook',
      setupInfo:
        'Configure su punto final de webhook para recibir eventos en vivo de Keyo o aprenda más sobre Webhooks.',
      endpointURL: 'URL del punto final',

      webhookEdit: 'Edición de Webhook',
      saveEndpoint: 'Guardar punto final',

      retrieveSecretError: 'Error inesperado: No se puede recuperar el secreto del webhook',
    },
    zendesk: {
      signingIn: 'Iniciando sesión en Zendesk',
    },
    personal: {
      components: {
        onBoardingProgress: {
          title: 'Configuracion - {percentage}% completado',
        },
        activateAccountWidget: {
          description:
            'Por favor, activa tu cuenta usando el mensaje que enviamos a tu correo electrónico o número de teléfono.',
          done: 'Hecho',
        },
        joinedBannersWidgets: {
          welcomeToOrg: 'Bienvenido a {name}',
        },
        cardChangeMfaMethod: {
          ifHaveNotReceivedCode:
            'Si no has recibido el código, elige un método de verificación alternativo.',
        },
        policiesWidget: {
          heading: 'Tu consentimiento es muy importante para nosotros',
          description:
            'Encontrarás todas tus pólizas aceptadas y firmadas aquí. Simplemente haz clic en la póliza que deseas revisar.',
        },
        policyCard: {
          acceptanceDate: 'Fecha de aceptación',
        },
        qrScanCard: {
          label: 'inscripción',
          enrollTitle: '¡Inscribir tu palma es fácil!',
          enrollStep1: 'Visita uno de nuestros dispositivos de inscripción Keyo Wave+.',
          enrollStep2: {
            part2: '"Inscribir tu palma"',
            part3: 'del menú.',
          },
          enrollStep3: {
            part2: '"Escanear código QR"',
            part3: 'y usa tu dispositivo móvil.',
          },
          unlockTitle: 'Desbloquea el mundo con un Movimiento!',
          unlockSubtitle:
            'Para inscribir tu palma, por favor visita uno de nuestros dispositivos Wave+ y toca "Escanear código QR".',
        },
        invitationCard: {
          actionRequired: 'Acción requerida',
          youAreInvited: '¡Has sido invitado!',
          weArePleasedToInformYou:
            'Nos complace informarte que has sido invitado a unirte a {name}',
        },
      },
      modals: {
        noAccessToCamera: {
          heading: 'Por favor, habilita el acceso a tu cámara',
          description: {
            part1:
              'Para continuar necesitarás permitir el acceso a la cámara en tu configuración. Hemos preparado una guía para ti',
            part2: 'aqui.',
          },
        },
        qrScanner: {
          invalidQrMessage:
            'El código QR escaneado no es válido para el registro. Por favor, intenta de nuevo mas tarde.',
        },
      },
      pages: {
        addAddress: 'Agregar dirección',
        editProfile: 'Editar perfil',
        home: {
          keyoLogo: 'Logo de Keyo',
          greeting: 'Hola, {name}!',
          welcomeDescription: {
            keyoDashboard: 'Panel de Keyo',
            part1: 'Te damos la bienvenida a tu',
            part2: 'Aquí, puedes gestionar tu cuenta, biometría y métodos de pago.',
          },
          transactionsWidget: {
            label: 'Transacciones',
          },
          networkWidget: {
            label: 'Tu red',
          },
          educationWidget: {
            title: 'Por qué te encantará ser miembro de Keyo',
            description: 'Aprende más sobre ser miembro.',
          },
          privacyDesignWidget: {
            title: 'Cómo Keyo incorpora la privacidad desde el diseño',
          },
          setupWidget: {
            title: 'Revisa tu perfil y elige un nombre para mostrar',
          },
        },
        invitations: {
          accepted: 'Aceptado',
          declined: 'Declinado',
          decline: 'Declinar',
          noInvitations: 'No tienes invitaciones',
        },
      },
      // Pinia - Named as store to avoid being tied to a specfic store implementation
      store: {
        uploadPhotoSuccess: 'La foto ha sido actualizada',
        uploadPhotoError: 'Error al subir la foto',

        deletePhotoError: 'Error al eliminar la foto',
      },
    },
    account: {
      common: {
        securityCodeSentToPhone:
          'Se enviará un código de seguridad a {phone}. Por favor, introduce el código de 6 dígitos a continuación.',
        securityCodeSentToMethod:
          'Se enviará un código de seguridad a {method}. Por favor, introduce el código de 6 dígitos a continuación.',
        changeEmail: 'Cambiar correo electrónico',
        changePhoneNumber: 'Cambiar número de teléfono',
      },
      components: {
        phoneAdd: {
          toAddEnterPhoneNumber:
            'Para agregar tu número de teléfono, por favor ingresa tu número de teléfono en el campo de abajo.',
        },
        cardAccountDelete: {
          ownerWarning:
            'Como propietario de la organización, tienes la opción de eliminar tu cuenta. Sin embargo, ten en cuenta que si eliges eliminar tu cuenta, deberás asignar a otra persona como nuevo propietario de la organización. Una vez que se elimine tu cuenta, ya no tendrás acceso a las funciones de gestión y administrativas de la organización.',
          generalWarning:
            'Si has experimentado un problema con tu cuenta y necesitas ayuda, contáctanos para que podamos ayudarte.',
          deletionNote: {
            part1: 'Por favor, ten en cuenta que eliminar tu cuenta, como se indica en los',
            part2: 'también resultará en la eliminación de tus datos biométricos de acuerdo con la',
            part3:
              'Esta acción desactivará las funciones de autenticación y pago biométricos, lo que significa que ya no podrás usar tu información biométrica para acceder o realizar pagos en este sistema.',
          },
          deleteAccountConfirmation: '¿Estás seguro de que quieres eliminar tu cuenta?',
        },
      },
      modals: {
        accountBiometricAlreadyExists: {
          description: 'Lo sentimos, ya has añadido tus datos biométricos a tu cuenta.',
        },
        accountBiometricConfirmEnroll: {
          almostDone: '¡Casi listo!',
          followInstructions: 'Sigue las instrucciones en el Keyo Wave+',
          startEnrollment: 'Iniciar inscripción',
          startEnrollmentQuestion: 'Te gustaría comenzar la inscripción en:',
          deviceMissingError: 'Falta el dispositivo',
          organizationMissingError: 'Falta la organización',
        },
        accountBiometricDelete: {
          heading: 'Eliminar tu biometría',
          description: {
            part1:
              'Por favor, introduce el código de seguridad que hemos enviado a tu {method} a continuación. Una vez que pulses en',
            part3: ', tus datos biométricos se eliminarán permanentemente de Keyo.',
          },
          deleteBiometric: 'Eliminar biometría',
        },
        accountBiometricDeleteSuccess: {
          heading: 'Biometría eliminada',
          description: 'Tus datos biométricos han sido eliminados basado en nuestros',
        },
        accountDeleteSuccess: {
          heading: 'Cuenta eliminada',
          description: 'Tu cuenta ha sido eliminada basado en nuestros',
        },
        accountPasswordChangeSuccess: {
          description: 'Ahora puedes usar tu nueva contraseña para iniciar sesión en tu cuenta.',
        },
        accountEmailChangeSuccess: {
          description:
            'Ahora puedes usar tu nuevo correo electrónico para iniciar sesión en tu cuenta.',
        },
        accountSecurityMfaConfirm: {
          change2faAuthentication: 'Cambiar autenticación de dos factores',
          areYouSureYouWantToChange2fa:
            '¿Estás seguro de que quieres cambiar el método de autenticación de dos factores predeterminado a {method}?',
          changeTo: 'Cambiar a {method}',
        },
        accountSecurityMfaSuccess: {
          description:
            'Has cambiado tu método de autenticación de dos factores predeterminado a {method}.',
        },
        accountVerifyMethodSuccess: {
          methodHasBeenVerified: 'Tu {method} ha sido verificado con éxito.',
        },
        accountPhoneChangeSuccess: {
          description:
            'Ahora puedes usar tu nuevo número de teléfono para iniciar sesión en tu cuenta.',
        },
        accountPhoneAddSuccess: {
          description: 'Ahora puedes usar tu número de teléfono para iniciar sesión en tu cuenta.',
        },
        accountEmailStep1: {
          toAddEnterEmail:
            'Para agregar tu correo electrónico, por favor ingresa tu correo electrónico.',
        },
        accountEmailAddSuccess: {
          description: 'Ahora puedes usar tu correo electrónico para iniciar sesión en tu cuenta.',
        },
        accountDelete: {
          description: {
            part1:
              'Por favor, introduce el código de seguridad que te enviamos a tu {method} a continuación. Una vez que pulses en',
            part2: 'tu cuenta será eliminada según se indica en nuestros',
          },
          termsOfUse: 'Términos de Servicio',
        },
        accountEmailChangeStep1: {
          description:
            'Se enviará un código de seguridad a {email}. Por favor, introduce el código de 6 dígitos a continuación.',
        },
        accountEmailChangeStep2: {
          heading: 'Agregar nuevo correo electrónico',
          description:
            'Para actualizar tu correo electrónico, por favor introduce tu nuevo correo electrónico en el campo de abajo.',
          labelNewEmail: 'Introduce el nuevo correo electrónico',
        },
        accountEmailChangeStep3: {
          heading: 'Confirmar nuevo correo electrónico',
          description:
            'Se enviará un código de seguridad a {newEmail}. Por favor, introduce el código de 6 dígitos a continuación.',
        },
        accountPasswordChange: {
          heading: 'Cambiar contraseña',
          description:
            'Para actualizar tu contraseña, por favor introduce tu contraseña actual en el campo de abajo.',
          currentPassword: 'Contraseña actual',
          newPassword: 'Nueva contraseña',
          confirmNewPassword: 'Confirmar nueva contraseña',
          step1: 'Paso 1',
          step2: 'Paso 2',
        },
        accountPhoneChangeStep2: {
          description:
            'Para actualizar su número de teléfono, por favor ingrese su nuevo número de teléfono en el campo proporcionado a continuación.',
          labels: {
            enterNewPhone: 'Ingrese nuevo número de teléfono',
          },
        },
        accountPhoneChangeStep3: {
          heading: 'Confirmar nuevo número de teléfono',
          description:
            'Se enviará un código de seguridad a {newPhone}. Por favor, introduce el código de 6 dígitos a continuación.',
        },
      },
      pages: {
        profileEdit: {
          uploadInfo: 'Puede subir un archivo .jpg o .png del usuario de hasta 2MB.',
        },
        security: {
          passwordTitle: 'Contraseña',
          changeButton: 'Cambiar',
          addButton: 'Añadir',
          passwordPlaceholder: '*************',
        },
        securityMfa: {
          updateYourMfa:
            'Puedes actualizar tu método predeterminado para la autenticación de dos factores aquí.',
          receiveCodeMethod: 'Recibe el código de verificación a través de {method}.',
          verifyMethod:
            'Para recibir el código de verificación a través de {method} necesitas completar la verificación.',
        },
      },
    },
    organization: {
      requirements: {
        connectMobileWallet: 'Conecta tu billetera móvil',
      },
      modals: {
        organizationRequirements: {
          title: 'Requisitos de la organización',
          toJoinOrganizationCompleteRequirements:
            'Para unirte a esta organización, por favor completa los siguientes requisitos.',
          requirements: {
            paymentProvider: {
              enterPhoneMobileWallet:
                'Ingresa el número de teléfono vinculado a tu billetera móvil.',
              ensurePhoneNumberIsIn: 'Asegúrate de que tu número esté en ',
              networks: 'la red {networks}. | las redes {networks}.',
            },
          },
        },
      },
    },
  },
  'pages': {
    sadAlt: 'triste',
    org: {
      members: {
        renewMember: 'Renovar miembro',
        pauseMember: 'Pausar miembro',
        removeMember: 'Remover miembro',
        viewProfile: 'Ver perfil',
        pause: 'Pausa',
        renew: 'Renovar',
        members: 'Miembros',
        orgMembers: 'Aquí están los miembros de tu organización.',
        exportCSV: 'Exportar CSV',
        modals: {
          membersRemove: {
            'member': 'miembro | miembros',
            'removeMember?': "¿Remover @:{'member'}?",
            'areYouSureToRemove': '¿Estás seguro de que quieres remover',
            'removingMemberOnlyRevokesAccess':
              'Remover un miembro de la organización solo revoca su acceso a tu organización y no eliminará su cuenta.',
            'membersRemoved': '@.capitalize:miembro ha sido removido de tu organización.',
          },
        },
      },
      devices: {
        title: 'Dispositivos',
        description: 'Aquí están los dispositivos de tu organización.',
        model: 'Modelo',
        serialNumber: 'Número de serie',
        generalInformation: 'Información general',
        deviceLocation: 'Ubicación del dispositivo',
        uploadDescription: 'Puedes subir fotos del área del terminal para mostrar su ubicación.',
        deviceName: 'Nombre del dispositivo',
        location: 'Ubicación',
        view: 'Ver',
        unexpectedError: 'Error inesperado durante la carga de datos del dispositivo',
        photoAdded: 'Foto de ubicación añadida',
        uploadError: 'Error al subir la foto de ubicación',
        deleteError: 'Error al eliminar la foto de ubicación',
      },
      generalForm: {
        nameRequired: 'El nombre es obligatorio',
        countryRequired: 'El país es obligatorio',
        stateRequired: 'El estado es obligatorio',
        cityRequired: 'La ciudad es obligatoria',
        zipRequired: 'El código postal es obligatorio',
        streetRequired: 'La calle es obligatoria',
      },
      businessProfileForm: {
        primaryContactFirstNameLabel: 'Contacto Principal (Nombre)',
        primaryContactLastNameLabel: 'Contacto Principal (Apellido)',
        primaryEmailLabel: 'Correo  Principal',
      },
      billingAddressForm: {
        countryLabel: 'País',
        stateLabel: 'Estado',
        stateRegionLabel: 'Estado/Región',
        cityLabel: 'Ciudad',
        zipLabel: 'Código Postal',
        streetLabel: 'Calle',
      },
      organizationLogo: {
        heading: 'Logotipo de la empresa',
        subtitle:
          'Se mostrará en la barra lateral izquierda. Por favor, suba un archivo .jpg o .png de hasta 1Mb.',
        logoUpdateSuccess: '¡Logotipo actualizado!',
        logoRemoveSuccess: 'Logotipo eliminado',
      },
      uploadFile: {
        text: 'Haga clic para subir',
        uploadIcon: 'icono de subida',
        alt2: 'icono de cruz',
      },
    },
    notFoundOrNoAccess: {
      message: 'No existe tal página o no hay acceso para verla',
    },
    pageNotFound: {
      message: 'Algo falta.',
    },
  },
  'utils': {
    validations: {
      required: {
        default: 'Este campo es obligatorio',
      },
      letters: {
        default: 'Solo puede contener letras',
      },
      email: {
        default:
          "Asegúrate de proporcionar una dirección de correo electrónico con el formato correcto (por ejemplo, ejemplo{'@'}ejemplo.com)",
      },
      phone: {
        default: 'Número inválido.',
        possiblePhone: 'Debe ser un formato internacional que comience con +.',
      },
      emailOrPhone: {
        default: 'Por favor ingresa un correo electrónico o número de teléfono',
        formatPhone:
          "Por favor ingresa un número de teléfono válido en el formato '+11234567890'. Se permiten hasta 15 dígitos.",
        validEmailOrPhone:
          "Por favor ingresa un número de teléfono válido en el formato '+11234567890' o proporciona una dirección de correo electrónico con el formato correcto (por ejemplo, ejemplo{'@'}ejemplo.com)",
      },
      max: {
        default: 'La longitud máxima es de {length}',
      },
      min: {
        default: 'La longitud mínima es de {length}',
      },
      match: {
        default: 'Debe coincidir con el campo {name}.',
      },
      confirm: {
        default: 'Por favor confirma tu {name}',
      },
      password: {
        default: 'Por favor ingresa tu contraseña',
        weakPassword: 'La contraseña es débil.',
      },
      name: {
        default: 'Solo se permiten letras, guiones, apóstrofes y puntos',
      },
      url: {
        default: 'URL inválida.',
      },
      mfaCode: {
        default: 'Debe ser un código de 6 dígitos.',
        shouldBeArray: 'Debe ser un arreglo.',
      },
    },
  },
}
